<template>
  <div>
    <DataTableComponent :table="table"></DataTableComponent>
  </div>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";
export default {
  data() {
    return {
      table: {
        modal: "DiscountPackage",
        path: "discount_packages",
        headers: [
          {
            text: this.$i18n.t("Name"),
            value: "en.name",
          },
          {
            text: this.$i18n.t("Arabic Name"),
            value: "ar.name",
          },
          {
            text: this.$i18n.t("Percentage"),
            value: "amount",
          },
          { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
        ],
        title: this.$i18n.t("Discounts"),
        apiEndPoints: {
          list: "discount_packages",
          create: "discount_packages/store",
          edit: "discount_packages/update",
          // delete: "discount_packages/destroy",
        },
        editedItem: {
          en: {
            name: "",
          },
          ar: {
            name: "",
          },
          amount: "",
        },
        permissions: {
          list: "list-discount-packages",
          add: "add-discount-packages",
          edit: "edit-discount-packages",
          // delete: "delete-discount-packages",
        },
      },
    };
  },
  methods: {},
  components: {
    DataTableComponent,
  },
};
</script>

<style></style>
